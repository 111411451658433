import request from '../request';
import config from '../config';

var current = config.url;
// var current = location.origin + '/';
export var tokenInfo = {
	//testnet
	// 'token': '0x1dc6723689baCc114A3698b695d6D8E6c2c66182',
	// 'pool': '0xdbbd9fDb6ae7F39D64163735e25dA9270c9F30FD'
	'token': '0x3702117BB800dee5b833f72180C4310392Cdd6b9',
	'pool': '0x1c1c72E692Ba3BAe83309E3b8e59414EFb01dE31'
}

function retHeader() {
	const header = {
		'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, DELETE',
		'Access-Control-Allow-Origin': '*',
		'Content-Type': 'application/json',
		'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With',
		'Authorization': 'Bearer' + localStorage.getItem("aibotToken"),
	};
	return header;

}


function retHeaderFile() {
	const header = {
		'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, DELETE',
		'Access-Control-Allow-Origin': '*',
		'Content-Type': 'multipart/form-data',
		'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With',
		'Authorization': 'Bearer' + localStorage.getItem("aibotToken"),
	};
	return header;
}


export function withdraw(postData) {
	const data = request({
		url: current + 'api/wallet/withdraw',
		method: 'post',
		data: postData,
		headers: retHeader()
	});

	return Promise.resolve(data);
}

// export function getUserBonusRecord(postData, page) {
// 	const data = request({
// 		url: current + 'api/record/bonus-record?bonus=' + postData + '&page=' + page,
// 		method: 'get',
// 		headers: retHeader()
// 	});

// 	return Promise.resolve(data);
// }

export function createTicket(postData) {
	const data = request({
		url: current + 'api/ticket/create-ticket',
		method: 'post',
		data: postData,
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function getTicket(page) {
	const data = request({
		url: current + 'api/ticket/get-ticket?page=' + page,
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function readTicket(id) {
	const data = request({
		url: current + 'api/ticket/read-ticket?id=' + id,
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function getUserPackage(params) {
	const data = request({
		url: current + 'api/package/get-user-package'+params,
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function getLinxRecord(params) {
	const data = request({
		url: current + 'api/project/getLinxRecord'+params,
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function getDinvestRecord(params) {
	const data = request({
		url: current + 'api/project/getDinvestRecord'+params,
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function transfer(postData) {
	const data = request({
		url: current + 'api/wallet/wallet-transafer',
		method: 'post',
		data: postData,
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function purchaseLinx(postData) {
	const data = request({
		url: current + 'api/project/purchaseLinx',
		method: 'post',
		data: postData,
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function purchaseDinvest(postData) {
	const data = request({
		url: current + 'api/project/purchaseDinvest',
		method: 'post',
		data: postData,
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function setCoinInfo(postData) {
	const data = request({
		url: current + 'api/member/update-address',
		method: 'post',
		data: postData,
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function searchUser(postData) {
	const data = request({
		url: current + 'api/wallet/check-receiver',
		method: 'post',
		headers: retHeader(),
		data: postData,
	});

	return Promise.resolve(data);
}

export function memberInfo(postData) {
	const data = request({
		url: current + 'api/member/get-member-info',
		method: 'get',
		data: postData,
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function getGlobalSetting() {
	const data = request({
		url: current + 'api/global/lookup',
		method: 'get',
		// headers: retHeader()
	});

	return Promise.resolve(data);
}

export function teamByAddress(name) {
	const data = request({
		url: current + 'api/team/downline-new?name='+name,
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function depositRecord(params) {
	const data = request({
		url: current + 'api/wallet/boDepositRecord'+params,
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function withdrawRecord(params) {
	const data = request({
		url: current + 'api/wallet/withdraw-record'+params,
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function getTeamList(id) {
	const data = request({
		url: current + 'api/team/downline-new?parent='+id,
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function getPackage(postData) {
	const data = request({
		url: current + 'api/package/get-package',
		method: 'get',
		data: postData,
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function authLogin(postData) {
	const data = request({
		url: current + 'api/auth/walletLogin',
		method: 'post',
		data: postData,
		// headers: retHeader()
	});

	return Promise.resolve(data);
}

// export function upgradePackage(postData) {
// 	const data = request({
// 		url: current + 'api/package/upgrade-package',
// 		method: 'post',
// 		data: postData,
// 		headers: retHeader()
// 	});

// 	return Promise.resolve(data);
// }

export function setSecPwd(postData, token) {
	const data = request({
		url: current + 'api/member/set-secpassword',
		method: 'post',
		data: postData,
		headers: {
			'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, DELETE',
			'Access-Control-Allow-Origin': '*',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With',
			'Authorization': 'Bearer' + token,
		}
	});

	return Promise.resolve(data);
}

export function requestOTP(postData) {
	const data = request({
		url: current + 'api/member/requestUserOTP',
		method: 'post',
		data: postData,
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function checkOTP(postData) {
	const data = request({
		url: current + 'api/member/checkUserOTP',
		method: 'post',
		data: postData,
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function changePassword(postData) {
	const data = request({
		url: current + 'api/member/change-password',
		method: 'post',
		data: postData,
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function changeSecPassword(postData) {
	const data = request({
		url: current + 'api/member/change-secpassword',
		method: 'post',
		data: postData,
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function getUserWalletRecord(page) {
	const data = request({
		url: current + 'api/record/wallet-record' + page,
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}


// login API
export function login(postData) {
	const data = request({
		url: current + 'api/auth/login',
		method: 'post',
		data: postData
	});
	return Promise.resolve(data);
}



// ultbot
export function signUp(postData) {
	const data = request({
		url: current + 'api/auth/signup',
		method: 'post',
		data: postData,
		// headers: retHeader()
	});

	return Promise.resolve(data);
}

export function signIn(postData) {
	const data = request({
		url: current + 'api/auth/login',
		method: 'post',
		data: postData,
		// headers: retHeader()
	});

	return Promise.resolve(data);
}

export function upgradePackage(postData) {
	const data = request({
		url: current + 'api/package/upgrade-package',
		method: 'post',
		data: postData,
		headers: retHeader()
	});

	return Promise.resolve(data);
}


export function getUserPackageRecord(page, status) {
	const data = request({
		url: current + 'api/package/get-user-package?page=' + page + '&status=' + status,
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function getdepositRecord(page) {
	const data = request({
		url: current + 'api/wallet/boDepositRecord?page=' + page,
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function getWallet() {
	const data = request({
		url: current + 'api/wallet/getWallet',
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}


export function getupdagradePackage() {
	const data = request({
		url: current + 'api/package/get-upgrade-package',
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function getUserBonusRecord(postData, page) {
	const data = request({
		url: current + 'api/record/bonus-record?bonus=' + postData + '&page=' + page,
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}


export function userKYC(postData) {
	const data = request({
		url: current + 'api/member/UserKyc',
		method: 'post',
		data: postData,
		headers: retHeaderFile()
	});

	return Promise.resolve(data);
}


export function editProfile(postData) {
	const data = request({
		url: current + 'api/member/update-member-info',
		method: 'post',
		data: postData,
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function getdownLine(parent) {
	const data = request({
		url: current + 'api/team/downline-new?parent=' + parent,
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function getdownInfo(id) {
	const data = request({
		url: current + 'api/team/checkDownlineInfo?user_id=' + id,
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function cancelPackage(postData) {
	const data = request({
		url: current + 'api/package/cancelPackage',
		method: 'post',
		data: postData,
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function terminatePackage(postData) {
	const data = request({
		url: current + 'api/package/terminatePackage',
		method: 'post',
		data: postData,
		headers: retHeader()
	});

	return Promise.resolve(data);
}


export function setLanguage(postData) {
	const data = request({
		url: current + 'api/member/setLanguage',
		method: 'post',
		data: postData,
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function Setsecpassword(postData) {
	const data = request({
		url: current + 'api/member/set-secpassword',
		method: 'post',
		data: postData,
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function forgetPassword(postData) {
	const data = request({
		url: current + 'api/auth/reset-password',
		method: 'post',
		data: postData,
		headers: retHeaderFile()
	});

	return Promise.resolve(data);
}

export function usernameOTP(postData) {
	const data = request({
		url: current + 'api/global/usernameOTP',
		method: 'post',
		data: postData,
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function sendOTP(postData) {
	const data = request({
		url: current + 'api/global/sent-otp',
		method: 'post',
		data: postData,
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function checkUsernameOtp(username, otp) {
	const data = request({
		url: current + 'api/global/checkUsernameOtp?username=' + username + '&otp=' + otp,
		method: 'get',
	});

	return Promise.resolve(data);
}

export function getOTP(email, otp) {
	const data = request({
		url: current + 'api/global/check-otp?contact=' + email + '&otp=' + otp,
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}


export function autoRenew(postData) {
	const data = request({
		url: current + 'api/package/autoRenewPackage',
		method: 'post',
		data: postData,
		headers: retHeader()
	});

	return Promise.resolve(data);
}
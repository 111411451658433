import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import "./assets/styles/sass/global.scss";
import i18n from "./system/lang/lang.js";
import { createPinia } from "pinia";
import VueQRCodeComponent from 'vue-qrcode-component';
import { setLanguage, memberInfo } from "./system/request/api/api";
Vue.component('qr-code', VueQRCodeComponent);
Vue.prototype.$bus = new Vue();

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(createPinia())

Vue.config.productionTip = false

new Vue({
  watch: {
    "$i18n.locale": "setLang"
  },
  data(){
    return {
      isLoading: false,
      userInfo: {},
      teamList: [],
      currentAddress: '',
      msg:'',
      variant:'',
      type:'',
    }
  },
  methods: {
    changeLan(lan){
      localStorage.setItem('lan', lan);
      this.$i18n.locale = lan;
      let formData = new FormData();
      formData.append('language', this.$i18n.locale == 'zh' ? 'cn' : this.$i18n.locale);
      setLanguage(formData);
    },
    makeToast(variant = null, msg, type) {
      this.msg = msg;
      this.variant = variant;
      this.type = type;
      this.$bvModal.show('modal-message');
    },
    getInfo() {
      var self = this;
      self.isLoading = true;
      var result = memberInfo();
      result
        .then(function (value) {
          self.isLoading = false;
          self.userInfo = value.data.data;


        })
        .catch(function (error) {
          console.log(error);
        });
    },
    makesrcToast(msg) {
      this.msg = msg;
      this.$bvModal.show('modal-src-password');
    },

    makeprofileToast() {
      this.$bvModal.show('modal-edit-profile');
    },

    makecancelToast() {
      this.$bvModal.show('modal-cancel-message');
    },
  },
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')

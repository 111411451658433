
// import { useMetatool } from '@/system/crypto/config'

export default async (to, from, next) => {
  // let metaTool = useMetatool();
  var token = localStorage.getItem("aibotToken") || '';
  // var storedAddress = localStorage.getItem("address") || '';
  var startTime = localStorage.getItem("firstTime") || '0';
  // var currentAddress = '';

  // await metaTool.getCurrentAccountFromMetamask().then(res => {
  //   currentAddress = res[0] || '';
  //   if(storedAddress.length>0){
  //     if(currentAddress!=storedAddress){
  //       localStorage.removeItem('address');
  //       localStorage.removeItem('aibotToken');
  //       next("/login");

  //     }

  //   }

  // }).catch(error => {
  //   console.error(error);
  //   currentAddress = '';

  // });

  if (parseInt(startTime) == 0) {
    await localStorage.setItem('firstTime', '1');
    next('/tutorial?page=1');
  } else {
    // next();
    if (token.length > 0) {
      next();
    } else {
      localStorage.removeItem("aibotToken");
      next("/login");
    }

  }
};

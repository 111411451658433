import Vue from "vue";
import Router from "vue-router";
import NProgress from "nprogress";
import checkAuth from "./system/auth/authenticate.js";
Vue.use(Router);

// create new router

const routes = [
  {
    path: "/",
    component: () => import("./views"),
    redirect: "/homepage",
    beforeEnter: checkAuth,
    children: [
      {
        path: "/homepage",
        component: () => import("./views/home/homePage"),
      },
      {
        path: "/profit",
        component: () => import("./views/profit/profit"),
      },
      {
        path: "/main",
        component: () => import("./views/node/main"),
      },
      {
        path: "/subscribe",
        component: () => import("./views/subscribe/subscribe"),
      },
      {
        path: "/nature",
        component: () => import("./views/nature/nature"),
      },
      {
        path: "/mePage",
        component: () => import("./views/settings/mePage"),
      },

    ]
  },
  {
    path: "/ticket",
    beforeEnter: checkAuth,
    component: () => import("./views/ticket"),
    redirect: "/ticket/ticket",
    children: [
      {
        path: "ticket",
        beforeEnter: checkAuth,
        component: () => import("./views/ticket/ticket"),
      },
      {
        path: "ticketDetail",
        beforeEnter: checkAuth,
        component: () => import("./views/ticket/ticketDetail"),
      },
      {
        path: "createticket",
        beforeEnter: checkAuth,
        component: () => import("./views/ticket/createTicket"),
      },
    ]
  },
  {
    path: "/transfer",
    beforeEnter: checkAuth,
    component: () => import("./views/transfer/transfer")
  },
  {
    path: "/tutorial",
    component: () => import("./views/sessions/firstTime")
  },
  {
    path: "/new-account",
    component: () => import("./views/home/new_account")
  },
  {
    path: "/reset-password",
    beforeEnter: checkAuth,
    component: () => import("./views/home/reset_password")
  },
  {
    path: "/reset-security-password",
    beforeEnter: checkAuth,
    component: () => import("./views/home/reset_security_password")
  },
  {
    path: "/bank-info",
    beforeEnter: checkAuth,
    component: () => import("./views/home/bank_info")
  },
  {
    path: "/metamask",
    component: () => import("./views/metamask")
  },
  {
    path: "/setting",
    beforeEnter: checkAuth,
    component: () => import("./views/settings/setting")
  },
  {
    path: "/help",
    beforeEnter: checkAuth,  
    component: () => import("./views/settings/helpCenter/help")
  },
  {
    path: "/sales",
    beforeEnter: checkAuth,
    component: () => import("./views/profit/sales")
  },
  {
    path: "/memberTree",
    beforeEnter: checkAuth,
    component: () => import("./views/profit/memberTree")
  },
  {
    path: "/performance",
    beforeEnter: checkAuth,
    component: () => import("./views/profit/performance")
  },
  {
    path: "/team",
    beforeEnter: checkAuth,
    component: () => import("./views/settings/team")
  },
  {
    path: "/deposit",
    beforeEnter: checkAuth,
    component: () => import("./views/deposit/deposit")
  },
  {
    path: "/USDTwithdraw",
    beforeEnter: checkAuth,
    component: () => import("./views/withdraw/USDTwithdraw")
  },
  {
    path: "/withdraw",
    beforeEnter: checkAuth,
    component: () => import("./views/withdraw/withdraw")
  },
  {
    path: "/transfer",
    beforeEnter: checkAuth,
    component: () => import("./views/withdraw/transfer")
  },
  {
    path: "/record",
    beforeEnter: checkAuth,
    component: () => import("./views/settings/record")
  },
  {
    path: "/nodeRecord",
    beforeEnter: checkAuth,
    component: () => import("./views/node/nodeRecord")
  },
  {
    path: "/purchaseAIB",
    beforeEnter: checkAuth,
    component: () => import("./views/settings/purchaseCenter/purchaseAIB")
  },
  {
    path: "/invite",
    beforeEnter: checkAuth,
    component: () => import("./views/settings/inviteCenter"),
    children: [
      {
        path: "/inviteCode",
        beforeEnter: checkAuth,
        component: () => import("./views/settings/inviteCenter/inviteCode"),
      },
      {
        path: "/inviteCodeRecord",
        beforeEnter: checkAuth,
        component: () => import("./views/settings/inviteCenter/inviteCodeRecord"),
      },
      {
        path: "/inviteCenter",
        beforeEnter: checkAuth,
        component: () => import("./views/settings/inviteCenter/inviteCenter"),
      },
      {
        path: "/invitePage",
        beforeEnter: checkAuth,
        component: () => import("./views/settings/inviteCenter/invitePage"),
      }

    ]
  },
  {
    path: "/subscribe/record",
    beforeEnter: checkAuth,
    component: () => import("./views/subscribe/record")
  },
  {
    path: "/deposit/record",
    beforeEnter: checkAuth,
    component: () => import("./views/deposit/record")
  },
  {
    path: "/newinvite",
    beforeEnter: checkAuth,
    component: () => import("./views/invite/invite")
  },
  {
    path: "/treedetail/:id",
    name: "treeDetail",
    beforeEnter: checkAuth,
    component: () => import("./views/invite/treeDetail")
  },
  {
    path: "/wallet-address",
    beforeEnter: checkAuth,
    component: () => import("./views/home/wallet_address")
  },
  {
    path: "/kyc",
    beforeEnter: checkAuth,
    component: () => import("./views/kyc/kyc")
  },

  {
    path: "/bonusRecord",
    beforeEnter: checkAuth,
    component: () => import("./views/bonus/bonusRecord")
  },

  {
    path: "/bonus/record",
    beforeEnter: checkAuth,
    component: () => import("./views/bonus/record")
  },

  {
    path: "/profile",
    beforeEnter: checkAuth,
    component: () => import("./views/profile/profile")
  },
  {
    path: "/edit_profile",
    beforeEnter: checkAuth,
    component: () => import("./views/profile/edit_profile")
  },

  {
    path: "/securty_password",
    beforeEnter: checkAuth,
    component: () => import("./views/home/securty_password")
  },

  {
    path: "/login",
    beforeEnter: async (to, from, next) => {
      var startTime = localStorage.getItem("firstTime") || '0';
      if (parseInt(startTime) == 0) {
        await localStorage.setItem('firstTime', '1');
        next('/tutorial?page=1');
      } else {
        next();
      }

    },
    component: () => import("./views/sessions/loginPage")
  },
  {
    path: "/register",
    component: () => import("./views/sessions/registerPage")
  },
  {
    path: "/forget-password",
    component: () => import("./views/sessions/forget_password")
  },
  {
    path: "*",
    component: () => import("./views/pages/notFound")
  }
];

const router = new Router({
  mode: "history",
  linkActiveClass: "open",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  // If this isn't an initial page load.
  if (to.path) {
    // Start the route progress bar.

    NProgress.start();
    NProgress.set(0.1);
  }
  next();
});

router.afterEach(() => {
  // Remove initial loading
  const gullPreLoading = document.getElementById("loading_wrap");
  if (gullPreLoading) {
    gullPreLoading.style.display = "none";
  }
  setTimeout(() => NProgress.done(), 100);
});

export default router;
